<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col lg="4" md="6">
            <b-card title="Draft Information" id="first-row">
              <b-form-group label="Draft Name" label-for="draftName">
                <validation-provider #default="{ errors }" name="Draft Name" rules="required">
                  <b-form-input id="draftName" v-model="draftTitleInput" placeholder="Enter Draft Name" :state="errors.length > 0 ? false : null" :disabled="Boolean(selected.isDefault)" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Draft Description" label-for="draftDescription">
                <b-form-textarea v-model="draftDescriptionInput" :disabled="Boolean(isDefaultOption)"> </b-form-textarea>
              </b-form-group>
              <div class="form-checkbox">
                <b-form-checkbox v-model="isDefaultOption" :disabled="Boolean(selected.isDefault)" plain @change="selectDefault($event)">
                  Mark as Default. (Default draft cannot be changed or removed)
                </b-form-checkbox>
              </div>
            </b-card>
          </b-col>

          <!-- tag source -->
          <b-col lg="4" md="6">
            <b-card title="Active Columns" id="first-row">
              <draggable :list="previewHeaders" v-bind="dragOptions" :group="{ name: 'tags', put: true }" class="mb-1 cursor-move">
                <span v-for="(listItem, index) in previewHeaders" :key="index">
                  <b-badge class="mr-75 mb-1">
                    <span @click="updateColumn(listItem.id, listItem.value, listItem.title, listItem.percentage ? listItem.percentage : 15)">
                      {{ listItem.title }}
                    </span>
                  </b-badge>
                </span>
              </draggable>
            </b-card>
          </b-col>

          <!-- add tag -->

          <b-col lg="4" md="6">
            <b-card title="Inactive Columns" id="first-row">
              <draggable :list="hiddenHeaders" v-bind="dragOptions" :group="{ name: 'tags' }" class="mb-1 cursor-move">
                <span v-for="(listItem, index) in hiddenHeaders" :key="index">
                  <b-badge class="mr-75 mb-1">
                    <span>
                      {{ listItem.title }}
                    </span>
                  </b-badge>
                </span>
              </draggable>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-card title="Preview table">
              <div
                class="
                position-relative
                table table-striped
                cursor-move
                table-responsive
              "
              >
                <table
                  responsive
                  class="
                  table
                  position-relative
                  b-table
                  table-striped table-hover table-sm
                  b-table-selectable b-table-select-single
                "
                >
                  <thead role="rowgroup" class="thead-dark cursor-move">
                    <draggable v-model="previewHeaders" v-bind="dragOptions" tag="tr">
                      <th v-for="header in previewHeaders" :style="{ width: header.percentage * colsFactor + '%', 'text-align': 'center' }" :key="header.id" scope="col">
                        {{ header.title }}
                      </th>
                    </draggable>
                  </thead>
                  <tbody>
                    <tr v-for="item in previewList" :key="item.id">
                      <td style="text-align:center" v-for="header in previewHeaders" :key="header.id">
                        <span
                          v-if="
                            header.value != 'featuredImage' &&
                              header.value != 'status' &&
                              header.value != 'priceContractType' &&
                              header.value != 'received' &&
                              header.value != 'method' &&
                              header.value != 'message' &&
                              header.value != 'id' &&
                              header.value != 'quoteId' &&
                              header.value != 'stockNumber' &&
                              header.value != 'source' &&
                              header.value != 'reason'
                          "
                        >
                          {{ item[header.value] ? item[header.value] : 'N/A' }}
                        </span>

                        <span v-if="header.value == 'id'">
                          <strong class="text-primary">#{{ Number(item['id']) }}</strong>
                        </span>
                        <span v-if="header.value == 'saleQuoteId'">
                          <strong class="text-primary">#{{ Number(item['saleQuoteId']) ? item['saleQuoteId'] : 'N/A' }}</strong>
                        </span>
                        <span v-if="header.value == 'stockNumber'">
                          <span v-if="item['stockNumber'] == '' || item['stockNumber'] == null">{{ 'N/A' }}</span>
                          <strong v-else class="text-primary">#{{ item['stockNumber'] ? item['stockNumber'] : 'N/A' }}</strong>
                        </span>

                        <span v-if="header.value == 'sellerType'">
                          <b-badge v-if="item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
                          <b-badge v-if="item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
                        </span>

                        <span v-if="header.value == 'condition'">
                          <b-badge v-if="item.condition == 'NEW'" active variant="light-info" class="badge-glow ">New</b-badge>
                          <b-badge v-if="item.condition == 'USED'" variant="light-success" class="badge-glow ">Used </b-badge>
                        </span>

                        <span v-if="header.value == 'message'">
                          <div v-if="item.message.length < 30">{{ item.message }}</div>
                          <div v-else>{{ item.message.substring(0, 30) + '..' }}</div>
                        </span>

                        <span v-if="header.value == 'sellerTradeBalance'">
                          <span>{{ item.sellerTradeBalance ? formatPrice(item.sellerTradeBalance, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'creditIssued'">
                          <span>{{ item.creditIssued ? formatPrice(item.creditIssued, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'payableBySeller'">
                          <span>{{ item.payableBySeller ? formatPrice(item.payableBySeller, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'baseMsrp'">
                          <span>{{ item.baseMsrp ? formatPrice(item.baseMsrp, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'additionalOptionsTotal'">
                          <span>{{ item.additionalOptionsTotal ? formatPrice(item.additionalOptionsTotal, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'appraisedVehiclePrice'">
                          <span>{{ item.appraisedVehiclePrice ? formatPrice(item.appraisedVehiclePrice, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'creditDue'">
                          <span>{{ item.creditDue ? formatPrice(item.creditDue, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'msrpTotal'">
                          <span>{{ item.msrpTotal ? formatPrice(item.msrpTotal, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'listingPrice'">
                          <span>{{ item.listingPrice ? formatPrice(item.listingPrice, 2) : formatPrice(0) }}</span>
                        </span>

                        <span v-if="header.value == 'priceContractType'">
                          <b-badge v-if="item['priceContractType'] == 1" pill variant="light-success" class="badge-glow">Buy-in</b-badge>
                          <b-badge v-if="item['priceContractType'] == 2" pill variant="light-info" class="badge-glow">Trade-in <feather-icon v-if="item['tradeStatus'] == 1" icon="CheckCircleIcon" size="12"/></b-badge>
                          <b-badge v-if="item['priceContractType'] == 3" pill variant="light-primary" class="badge-glow">Lease Return</b-badge>
                        </span>

                        <span v-if="header.value == 'received'">
                          <b-badge v-if="item['received'] == 'yes'" pill variant="success" class="badge-glow">Vehicle Received</b-badge>
                          <b-badge v-if="item['received'] == 'no'" pill variant="warning" class="badge-glow">Vehicle Not Received</b-badge>
                        </span>

                        <span v-if="header.value == 'delivered'">
                          <b-badge v-if="item['delivered'] == 'yes'" pill variant="success" class="badge-glow">Vehicle Delivered</b-badge>
                          <b-badge v-if="item['delivered'] == 'no'" pill variant="warning" class="badge-glow">Vehicle Not Delivered </b-badge>
                        </span>
                        <span v-if="header.value == 'featuredImage'">
                          <b-img thumbnail class="rounded" width="100%" v-if="item['featuredImage'] != null" :src="baseURL + item['selectedImage']" />
                          <b-img thumbnail class="rounded" width="100%" v-if="item['featuredImage'] == null" src="@/assets/images/no-car.png" />
                        </span>
                        <span v-if="header.value == 'status'">
                          <b-badge v-if="item.status == 'A'" pill variant="secondary" class="badge-glow">Unassigned</b-badge>
                          <b-badge v-if="item.status == 'B'" pill variant="warning" class="badge-glow">Open</b-badge>
                          <b-badge v-if="item.status == 'C'" pill variant="primary" class="badge-glow">In Progress</b-badge>
                          <b-badge v-if="item.status == 'D'" pill variant="success" class="badge-glow">Completed</b-badge>
                          <b-badge v-if="item.status == 'E'" pill variant="info" class="badge-glow">Created Quote</b-badge>
                          <b-badge v-if="item.status == 'J'" pill variant="info" class="badge-glow">Junk</b-badge>
                          <b-badge v-if="item.status == 'P'" pill variant="secondary" class="badge-glow">Lost</b-badge>
                          <b-badge v-if="item.status == 'F'" pill variant="success" class="badge-glow">Win</b-badge>
                        </span>

                        <span v-if="header.value == 'source'">
                          <b-badge variant="light-danger" v-if="item.source == 'user'" style="font-size: 70%">USER</b-badge>
                          <b-badge variant="light-info" v-if="item.source == 'website'" style="font-size: 70%">WEBSITE</b-badge>
                        </span>

                        <span v-if="header.value == 'reason'">
                          <b-badge variant="light-info" style="font-size: 70%">{{ item.reason }}</b-badge>
                          <b-badge v-if="item.method == 'GENERAL ENQUIRY'" pill variant="success" class="badge-glow">GENERAL ENQUIRY</b-badge>
                          <b-badge v-if="item.method == 'VEHICLE ENQUIRY'" pill variant="info" class="badge-glow">VEHICLE ENQUIRY</b-badge>
                          <b-badge v-if="item.method == 'CAR HUNTING'" pill variant="info" class="badge-glow">CAR HUNTING</b-badge>
                          <b-badge v-if="item.method == 'TO SELL A VEHICLE'" pill variant="secondary" class="badge-glow">TO SELL A VEHICLE</b-badge>
                          <b-badge v-if="item.method == 'TO LEASE A VEHICLE'" pill variant="secondary" class="badge-glow">TO LEASE A VEHICLE</b-badge>
                        </span>

                        <span v-if="header.value == 'method'">
                          <b-badge v-if="item.method == 'SMS'" pill variant="success" class="badge-glow">SMS</b-badge>
                          <b-badge v-if="item.method == 'PHONE'" pill variant="info" class="badge-glow">PHONE</b-badge>
                          <b-badge v-if="item.method == 'EMAIL'" pill variant="info" class="badge-glow">EMAIL</b-badge>
                          <b-badge v-if="item.method == 'LETTER'" pill variant="secondary" class="badge-glow">LETTER</b-badge>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="mt-sm-2 mt-md-0">
            <b-card title="Active Users List">
              <div class="d-flex">
                <b-col cols="12">
                  <b-form-group class="mb-40">
                    <b-input-group>
                      <b-form-input id="filter-input" v-model="activeSearchQuery" type="search" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button class="list-button" @click="activeSearchQuery = ''" variant="primary">Clear</b-button>
                        <b-button v-show="!isDefaultOption" class="list-button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="addAllActive">
                          Add All Users
                        </b-button>

                        <b-button v-show="!isDefaultOption" class="list-button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="addAllInactive">
                          Remove All Users
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </div>
              <div @drop="dropActive">
                <!-- draggable -->
                <draggable v-bind="dragOptions" :list="displayActiveUsers" tag="ul" group="people" class="list-group list-group-flush cursor-move" id="grid-container">
                  <b-list-group-item v-for="(listItem, index) in displayActiveUsers" :key="index" tag="li" id="grid-item" v-bind:class="{ hidden: !showActive(listItem) }">
                    <b-row>
                      <div>
                        <b-media>
                          <template #aside>
                            <b-avatar v-if="listItem.name" :text="listItem.name.slice(0, 2)" />
                          </template>
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ listItem.name }}
                          </b-card-text>
                          <small>{{ listItem.email }}</small>
                        </b-media>
                      </div>
                    </b-row>
                  </b-list-group-item>
                </draggable>
              </div>
            </b-card>
          </b-col>

          <!-- people group 2 -->
          <b-col md="6" class="mt-sm-2 mt-md-0">
            <b-card title="Inactive Users List">
              <!-- draggable -->
              <div class="d-flex">
                <b-col cols="12">
                  <b-form-group class="mb-40">
                    <b-input-group>
                      <b-form-input id="filter-input" v-model="inactiveSearchQuery" type="search" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button class="list-button" @click="inactiveSearchQuery = ''" variant="primary">Clear</b-button>
                        <b-button v-show="!isDefaultOption" class="list-button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="addAllInactive">
                          Add All Users
                        </b-button>

                        <b-button v-show="!isDefaultOption" class="list-button" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="addAllActive">
                          Remove All Users
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </div>
              <div @drop="dropInactive">
                <draggable v-bind="dragOptions" :list="displayInactiveUsers" tag="ul" group="people" class="list-group list-group-flush cursor-move" id="grid-container">
                  <b-list-group-item v-for="(listItem, index) in displayInactiveUsers" :key="index" tag="li" id="grid-item" v-bind:class="{ hidden: !showInactive(listItem) }">
                    <b-row>
                      <b-media>
                        <template #aside>
                          <b-avatar v-if="listItem.name" :text="listItem.name.slice(0, 2)" />
                        </template>
                        <b-card-text class="mb-0 font-weight-bold">
                          {{ listItem.name }}
                        </b-card-text>
                        <small>
                          {{ listItem.email }}
                        </small>
                      </b-media>
                    </b-row>
                  </b-list-group-item>
                </draggable>
              </div>
            </b-card>
          </b-col>

          <b-col class="d-flex flex-row-reverse">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="primary" class="mb-5" @click.prevent="validationForm" v-show="!selected.isDefault">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>

      <b-modal id="modal-column" cancel-variant="outline-secondary" ok-title="Save Changes" @ok="handleOk" cancel-title="Close" centered title="Column Details">
        <validation-observer ref="modalRules">
          <b-form>
            <b-form-group :state="nameState" invalid-feedback="Column title already exists">
              <label for="columnTitle">Column Title:</label>
              <b-form-input id="columnTitle" type="text" v-model="selectedTitle" placeholder="Column Title" />
            </b-form-group>
            <b-form-group>
              <label for="Size">Column Size</label>
              <b-form-input type="range" v-model="selectedPercentage" class="form-range" min="10" max="100" step="5" id="percentage" />
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BTable, BListGroupItem, BFormCheckbox, BFormInput, BFormGroup, BBadge, BCardTitle, BCardText, BAvatar, BButton, BRow, BCol, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
import Ripple from 'vue-ripple-directive';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import draggable from 'vuedraggable';
import axiosIns from '@/libs/axios';
import { required } from '@validations';
import router from '@/router';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTable,
    BRow,
    BCol,
    BButton,
    BCardActions,
    BFormTextarea,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    SettingsCard,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BListGroupItem,
    draggable,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      colsFactor: null,
      selectedTitle: null,
      selectedPercentage: null,
      selectedId: null,
      selectedValue: null,
      loading: true,
      columns: [],
      previewList: [],
      selected: [],
      displayInactive: '',
      displayActive: '',
      activeSearchQuery: '',
      inactiveSearchQuery: '',
      required,
      editing: '',
      inactive: '',
      isDefaultOption: false,
      draftInfo: {
        draftTitle: '',
        draftDescription: '',
        activeUsers: '',
        activeColumns: '',
        isDefault: false,
        inactiveUsers: '',
        inactiveColumns: '',
      },
      draftTitleInput: '',
      draftDescriptionInput: '',
      displayInactiveUsers: [],
      displayActiveUsers: [],
      previewHeaders: [],
      hiddenHeaders: [],
      allUsers: [],
      colsList: [],

      titleValues: [],
    };
  },

  methods: {
    checkValidity() {
      const filtered = this.titleValues.filter((x) => x.title == this.selectedTitle && x.value != this.selectedValue);
      const success = filtered.length < 1;

      if (success) {
        this.modalOk();
        this.$nextTick(() => {
          this.$bvModal.hide('modal-column');
        });
      } else {
        return;
      }
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid

      this.checkValidity();
    },

    updateColumn(id, value, title, percentage) {
      this.$bvModal.show('modal-column');
      this.selectedTitle = title;
      this.selectedPercentage = percentage;
      this.selectedId = id;
      this.selectedValue = value;
    },

    modalOk() {
      this.formShow = true;
      this.titleValues.find((x) => x.value == this.selectedValue).title = this.selectedTitle;
      this.titleValues.find((x) => x.value == this.selectedValue).percentage = this.selectedPercentage;

      axiosIns
        .put(`columns/${this.selectedId}`, {
          type: 'lead',

          title: this.selectedTitle,
          value: this.selectedValue,
          percentage: this.selectedPercentage,
        })
        .then((res) => {
          if (res.status == 200) {
          }
        })
        .catch((error) => {
          console.log('Column update error ', error);
        });
    },
    getItems() {
      this.getPreview();
    },

    getPreview() {
      store
        .dispatch('settings/fetchLeadList', [])
        .then((response) => {
          if (response.status == '200') {
            this.previewList = response.data.leads;
            this.columns = response.data.headers;
            this.selected = this.columns.filter((item) => item.id == router.currentRoute.params.id)[0];
            this.columns = this.columns.filter((item) => item.id != this.selected.id);
            this.titleValues = response.data.columns;
            var total = 0;
            this.titleValues.forEach((element) => {
              this.colsList.push(element.value);
              total = total + element.percentage;
            });
            this.colsFactor = 100 / total;
            this.fetchData(response.data.users);

            this.defineColumns();
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Draft list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          console.log(error);
        });
    },

    fetchData(data) {
      this.allUsers = data;
      this.draftTitleInput = this.selected.title;
      this.draftDescriptionInput = this.selected.description;
      if (this.selected.isDefault == 0 || this.selected.isDefault == false || this.selected.isDefault == '0') {
        this.isDefaultOption = false;
      } else if (this.selected.isDefault == 1 || this.selected.isDefault == true || this.selected.isDefault == '1') {
        this.isDefaultOption = true;
      }

      this.previewHeaders = this.getCols(this.selected.activeColumns);

      this.hiddenHeaders = this.getCols(this.selected.inactiveColumns);

      if (this.selected.activeUsers != null) {
        this.displayActiveUsers = this.getUsers(this.allUsers, this.selected.activeUsers.split(','));
      } else this.selected.activeUsers = [];

      if (this.selected.inactiveUsers != null) {
        this.displayInactiveUsers = this.getUsers(this.allUsers, this.selected.inactiveUsers.split(','));
      } else {
        this.selected.inactiveUsers = [];
      }
    },

    getCols(cols) {
      if (cols != null) {
        return cols.split(',');
      } else {
        return [];
      }
    },

    getUsers(data, users) {
      const funcList = [];

      try {
        if (users) {
          users.forEach((element) => {
            data.forEach((x) => {
              if (parseInt(element) == parseInt(x.id)) {
                funcList.push(x);
              }
            });
          });

          return funcList;
        } else {
          return [];
        }
      } catch (TypeError) {
        return [];
      }
    },

    defineColumns() {
      this.hiddenHeaders = this.colsList.filter((el) => this.previewHeaders.indexOf(el) == -1);
      this.previewHeaders = this.previewHeaders.filter((el) => this.hiddenHeaders.indexOf(el) == -1);
      let storeArray = [];

      this.titleValues.forEach((element) => {
        if (this.hiddenHeaders.includes(element.value)) {
          storeArray.push(element);
        }
      });
      this.hiddenHeaders = storeArray;
      storeArray = [];
      this.previewHeaders.forEach((x) =>
        this.titleValues.forEach((element) => {
          if (x == element.value) {
            storeArray.push(element);
          }
        })
      );

      this.previewHeaders = storeArray;
      storeArray = [];
      this.loading = false;
    },

    async editItems(id) {
      const promises = [];

      if (this.draftInfo == '') {
        return;
      }

      if (this.displayInactiveUsers == 'undefined') {
        this.displayInactiveUsers = null;
      }
      if (this.displayActiveUsers == 'undefined') {
        this.displayActiveUsers = null;
      }

      this.columns.forEach((draft) => {
        if (!draft.activeUsers || draft.activeUsers == 'undefined') {
          draft.activeUsers = null;
        }
        if (!draft.inactiveUsers || draft.inactiveUsers == 'undefined') {
          draft.inactiveUsers = null;
        }

        if (this.draftInfo.isDefault == true && draft.isDefault == true) {
          let defaultInactive = '';
          let defaultActive = '';

          this.draftInfo.activeUsers.forEach(function(item) {
            defaultActive += String(item.id) + ',';
          });

          this.draftInfo.inactiveUsers.forEach(function(item) {
            defaultInactive += String(item.id) + ',';
          });

          axiosIns
            .put(`headers/${draft.id}`, {
              type: 'lead',

              title: draft.draftTitle,
              description: draft.draftDescription,
              activeUsers: null,
              inactiveUsers: defaultActive.slice(0, -1),
              activeColumns: draft.activeColumns,
              inactiveColumns: draft.inactiveColumns,
              isDefault: false,
            })
            .then((res) => {
              if (res.status == 200) {
                ('successful');
              }
            })
            .catch((error) => {
              console.log('Update default error', error);
            });
        }

        if (this.draftInfo.isDefault == false || this.draftInfo.isDefault == '0' || this.draftInfo.isDefault == 0) {
          if (draft.activeUsers) {
            if (draft.activeUsers.indexOf(',') > -1) {
              this.draftInfo.activeUsers.forEach((user) => {
                if (draft.activeUsers.split(',').includes(String(user.id))) {
                  var array = [];
                  var arrayInactive = [];

                  array = draft.activeUsers.split(',');

                  array = array.filter((item) => item != String(user.id));
                  draft.activeUsers = array.toString();

                  if (draft.inactiveUsers) {
                    if (draft.inactiveUsers == 'undefined') {
                      draft.inactiveUsers = [];
                      draft.inactiveUsers.push(user.id);
                      draft.inactiveUsers.toString();
                    }
                    if (draft.inactiveUsers != 'undefined') {
                      arrayInactive = JSON.parse('[' + draft.inactiveUsers + ']');
                      arrayInactive.push(parseInt(user.id));
                      draft.inactiveUsers = arrayInactive.toString();
                    }
                  } else if (!draft.inactiveUsers) {
                    arrayInactive.push(parseInt(user.id));
                    draft.inactiveUsers = arrayInactive.toString();
                  }
                }
              });
            }
            if (!draft.activeUsers.indexOf(',') > -1) {
              var arrayInactive = [];

              this.draftInfo.activeUsers.forEach((user) => {
                if (draft.activeUsers == String(user.id)) {
                  draft.activeUsers = null;

                  if (draft.inactiveUsers) {
                    if (draft.inactiveUsers == 'undefined') {
                      draft.inactiveUsers = [];
                      draft.inactiveUsers.push(user.id);
                      draft.inactiveUsers.toString();
                    }
                    if (draft.inactiveUsers != 'undefined') {
                      if (draft.inactiveUsers.toString().indexOf(',') > -1) {
                        arrayInactive = draft.inactiveUsers.split(',');
                        arrayInactive.push(user.id);
                        draft.inactiveUsers = arrayInactive.toString();
                      } else if (!draft.inactiveUsers.toString().indexOf(',') > -1) {
                        const array = [];
                        array.push(draft.inactiveUsers);
                        array.push(String(user.id));
                        draft.inactiveUsers = array.toString();
                      }
                    }
                  }
                }
              });
            }
          }
          if (!draft.activeUsers) {
          }
        }

        if (draft.isDefault == false || draft.isDefault == '0' || draft.isDefault == 0) {
          promises.push(
            axiosIns.put(`headers/${draft.id}`, {
              type: 'lead',
              title: draft.draftTitle,
              description: draft.draftDescription,
              activeUsers: draft.activeUsers,
              inactiveUsers: draft.inactiveUsers,
              activeColumns: draft.activeColumns,
              inactiveColumns: draft.inactiveColumns,

              isDefault: draft.isDefault,
            })
          );
        }
      });

      var st = '';

      this.draftInfo.activeUsers.forEach(function(item) {
        st += String(item.id) + ',';
      });
      this.draftInfo.activeUsers = st.slice(0, -1);
      st = '';
      this.draftInfo.inactiveUsers.forEach(function(item) {
        st += String(item.id) + ',';
      });
      this.draftInfo.inactiveUsers = st.slice(0, -1);

      promises.push(
        await axiosIns
          .put(`headers/${id}`, {
            type: 'lead',
            title: this.draftInfo.draftTitle,
            description: this.draftInfo.draftDescription,
            activeUsers: this.draftInfo.activeUsers,
            inactiveUsers: this.draftInfo.inactiveUsers,
            activeColumns: this.draftInfo.activeColumns,
            inactiveColumns: this.draftInfo.inactiveColumns,
            isDefault: this.draftInfo.isDefault,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$swal({
                title: 'Success!',
                text: 'The draft has been updated successfully!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });

              router.push({ name: 'settings-lead' });
            }
          })
          .catch((error) => {
            this.$swal({
              title: 'Error!',
              text: 'The draft could not be registered!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
            console.log(error);
          })
      );
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const s = this;
          this.draftInfo.draftTitle = this.draftTitleInput;
          this.draftInfo.draftDescription = this.draftDescriptionInput;
          this.draftInfo.isDefault = this.isDefaultOption;
          this.draftInfo.activeUsers = this.displayActiveUsers;
          this.draftInfo.inactiveUsers = this.displayInactiveUsers;

          this.previewHeaders.forEach(function(item) {
            s.draftInfo.activeColumns += String(item.value) + ',';
          });
          this.draftInfo.activeColumns = this.draftInfo.activeColumns.slice(0, -1);

          this.hiddenHeaders.forEach(function(item) {
            s.draftInfo.inactiveColumns += String(item.value) + ',';
          });
          this.draftInfo.inactiveColumns = this.draftInfo.inactiveColumns.slice(0, -1);

          if (this.isDefaultOption) {
            this.$swal({
              title: 'The draft will be saved as default.',
              text: '',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, save the default draft.',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.editItems(this.selected.id);
              }
            });
          } else if (!this.isDefaultOption) {
            this.$swal({
              title: 'Are you sure?',
              text: '',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, update the draft.',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1',
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.editItems(this.selected.id);
              }
            });
          }
        } else if (!success) {
          alert('Please provide the required information.');
        }
      });
    },

    selectDefault($event) {
      if ($event) {
        this.addAllActive();
      } else if (!$event) {
        this.addAllInactive();
      }
    },

    addAllActive() {
      this.displayActiveUsers = this.displayActiveUsers.concat(this.displayInactiveUsers);
      this.displayInactiveUsers = [];
    },
    addAllInactive() {
      this.displayInactiveUsers = this.displayInactiveUsers.concat(this.displayActiveUsers);
      this.displayActiveUsers = [];
    },

    showActive(item) {
      if (this.displayActive.includes(item)) {
        return true;
      } else if (this.activeSearchQuery == '') {
        return true;
      } else {
        return false;
      }
    },

    showInactive(item) {
      if (this.displayInactive.includes(item)) {
        return true;
      } else if (this.inactiveSearchQuery == '') {
        return true;
      } else {
        return false;
      }
    },
    dropActive() {
      this.activeSearchQuery = '';
    },
    dropInactive() {
      this.inactiveSearchQuery = '';
    },
    isEditing(condition, id, value, title) {
      if (condition) {
        this.editing = value;
        this.inactive = value;
      } else if (!condition) {
        this.titleValues.find((x) => x.value == value).title = title;
        this.editing = '';
        this.inactive = '';

        axiosIns
          .put(`columns/${id}`, {
            type: 'lead',

            title: title,
            value: value,
          })
          .then((res) => {
            if (res.status == 200) {
              ('Column updated');
            }
          })
          .catch((error) => {
            console.log('Column update error ', error);
          });
      }
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: this.isDefaultOption,
        ghostClass: 'ghost',
      };
    },

    nameState() {
      const filtered = this.titleValues.filter((x) => x.title == this.selectedTitle && x.value != this.selectedValue);
      const success = filtered.length < 1;
      return success;
    },
  },

  watch: {
    inactiveSearchQuery() {
      if (this.inactiveSearchQuery.length < 1) {
        this.displayInactive = this.displayInactiveUsers;
      } else {
        this.displayInactive = this.displayInactiveUsers.filter((displayInactiveUsers) => displayInactiveUsers.name.toLowerCase().includes(this.inactiveSearchQuery));
      }
    },

    activeSearchQuery() {
      if (this.activeSearchQuery.length < 1) {
        this.displayActive = this.displayActiveUsers;
      } else {
        this.displayActive = this.displayActiveUsers.filter((displayActiveUsers) => displayActiveUsers.name.toLowerCase().includes(this.activeSearchQuery));
      }
    },
  },

  mounted() {
    this.getItems();
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return { selecteds: ['Admin', 'User'] };
  },
};
</script>

<style>
.edit-column {
  border-radius: 10%;
  font-weight: bold;
  overflow: hidden;
  max-width: 30%;
  height: 25%;
  color: white;
  background-color: #68ea8b !important;
  text-align: center;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 3px;
  outline-color: white;
  border: white;
  font-size: small;
}

.list-button {
  margin-right: 5px;
}

.hidden {
  display: none !important;
}
#first-row {
  display: flex;
  height: 300px;
  width: 100%;
  overflow: auto;
}

#grid-container {
  grid-auto-flow: row;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;
  height: 400px;
  align-content: start;
  gap: 2px;
}

#grid-item {
  display: block;
  border: 1px solid rgb(233, 232, 232);
  height: 75px;
}

.list-group-item {
  transition: all 1s;
}

.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
